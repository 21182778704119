import React, { Component } from 'react';
import img_modal from '../../assets/images/img_modal.jpg';
import icon_close from '../../assets/images/icons/icon-close.png';
import svg_modal from '../../assets/images/icon_modal.svg';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import Swal from 'sweetalert2'

class Brochure extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: "",
            surname: "",
            email: "",
            phone_number: "",
            sending: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleClose = () => this.props.setShow(false);
    handleShow = () => this.props.setShow(true);
    handleSubmit(event){
        event.preventDefault();
        let form = document.querySelector('.validate');
        if (form.checkValidity() === false) {
            //Campos invalidos
            event.preventDefault();
            event.stopPropagation();
        }else{
            //TODO: Verificar captcha
            //Hacemos submit
            this.setState({...this.state, sending: true});
            axios.post(`${process.env.REACT_APP_API_URL}brochure`, this.state)
            .then(res => {
                if((res.status === 200) && (res.data.success)){
                    // Mostramos mensaje enviado
                    if(localStorage.getItem('language') === 'es'){
                        Swal.fire(
                            'Operación exitosa',
                            'Recibirás un correo con la información de nuestro brochure',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Success',
                            'You\'ll receive an email with information about our brochure',
                            'success'
                        )
                    }
                }else{
                    if(localStorage.getItem('language') === 'es'){
                        Swal.fire(
                            'Oops!',
                            'Lo sentimos, algo salió mal',
                            'error'
                        )
                    }else{
                        Swal.fire(
                            'Oops!',
                            'Sorry, something went wrong',
                            'error'
                        )
                    }
                }
                this.setState({...this.state, sending: false});
            })
            .catch(err => {
                console.log(err);
                Swal.fire(
                    'Oops!',
                    'Lo sentimos, algo salió mal',
                    'error'
                )
                this.setState({...this.state, sending: false});
            });
        }
        form.classList.add('was-validated');
    }
    showButton(){
        if(this.state.sending){
            return <button className="btn btn-cafe text-uppercase"><FormattedMessage id="app.enviando" defaultMessage="Enviando..."></FormattedMessage></button>
        }else{
            return <button className="btn btn-cafe text-uppercase" onClick={this.handleSubmit}><FormattedMessage id="app.download_now" defaultMessage="Descargar Ahora"></FormattedMessage></button>
        }
    }
    render() {
        return (
            <Modal show={this.props.show} onHide={this.handleClose} size="xl" aria-labelledby="contained-modal-title-vcenter" centered className="mdBrochure">
                <Modal.Header className="position-absolute justify-content-end">
                    <img src={icon_close} alt="Close" className="pointer" onClick={this.handleClose}></img>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-sm-12 bg-grey-1 pt-5 pb-5 p-md-5 order-2 order-lg-1">
                                <h2 className="text-uppercase text-white m30"><FormattedMessage id="app.conoce_sobre_nuestra_comunidad" defaultMessage="Conoce sobre nuestra comunidad"></FormattedMessage></h2>
                                <p className="text-white m30"><FormattedMessage id="app.dejanos_tus_datos_y_uno_de_nuestros_asesores_te_contactara" defaultMessage="Déjanos tus datos y uno de nuestros asesores te contactará."></FormattedMessage></p>
                                <form className="validate" noValidate>
                                    <div className="row m40">
                                        <div className="col-lg-6 col-sm-12 mb-4">
                                            <FormattedMessage id="app.name" defaultMessage="Nombre">
                                                {placeholder=>  
                                                    <input className="form-control text-uppercase" placeholder={placeholder} onChange={(el) => {this.setState({...this.state, name: el.target.value})}} required></input>
                                                }
                                            </FormattedMessage>
                                        </div>
                                        <div className="col-lg-6 col-sm-12 mb-4">
                                            <FormattedMessage id="app.surname" defaultMessage="Apellido">
                                                {placeholder => 
                                                    <input className="form-control text-uppercase" placeholder={placeholder} onChange={(el) => {this.setState({...this.state, surname: el.target.value})}} required></input>
                                                }
                                            </FormattedMessage>
                                        </div>
                                        <div className="col-lg-6 col-sm-12 mb-4">
                                            <FormattedMessage id="app.email" defaultMessage="Correo">
                                                {placeholder => 
                                                    <input className="form-control text-uppercase" placeholder={placeholder} onChange={(el) => {this.setState({...this.state, email: el.target.value})}} required></input>
                                                }
                                            </FormattedMessage>
                                        </div>
                                        <div className="col-lg-6 col-sm-12 mb-4">
                                            <FormattedMessage id="app.telefono" defaultMessage="Teléfono">
                                                {placeholder =>
                                                    <input className="form-control text-uppercase" placeholder={placeholder} onChange={(el) => {this.setState({...this.state, phone_number: el.target.value})}}></input>
                                                }
                                            </FormattedMessage>
                                        </div>
                                    </div>
                                </form>
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <img src={svg_modal} alt="form"></img>
                                    </div>
                                    <div className="col-12 col-lg-6 text-right">
                                        {this.showButton()}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12 p-0 text-right order-1 order-lg-2">
                                <div className="bgg d-lg-none" style={{backgroundImage: `url(${img_modal})`}}>
                                    <img src={img_modal} alt="img3" style={{width:"100%"}}></img>
                                </div>
                                <img className="w-max-100 d-none d-lg-inline" src={img_modal} alt="img"></img>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default Brochure;