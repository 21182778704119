import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import './scss/amenities.scss';

//Images
import isotipo from './assets/images/isotipo_amenities.svg';
// import img1 from './assets/images/amenities_1.jpg';
// import img2 from './assets/images/amenities_2.png';
// import img3 from './assets/images/08_gimnasio.jpg';
import img4 from './assets/images/amenities_3.png';

class Amenities extends Component {
    render() {
        return (
            <div id="amenities">
                <div className="bg-grey-1 section-1">
                    <div className="container-fluid w14 w-lg-90">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-8 col-lg-8">
                                <p className="m30 text-uppercase subtlo-16" data-aos="fade-right">
                                    <FormattedMessage
                                        id="app.amenities"
                                        defaultMessage="Amenidades"
                                    ></FormattedMessage>
                                </p>
                                <h2 className="text-uppercase titulo-x2 m50 m-lg-100" data-aos="fade-right">
                                    <span className="text-white">
                                        <FormattedMessage
                                            id="app.espacios_para"
                                            defaultMessage="Espacios para"
                                        ></FormattedMessage>
                                    </span>
                                    <br></br>
                                    <FormattedMessage
                                        id="app.crear_comunidad"
                                        defaultMessage="Crear comunidad"
                                    ></FormattedMessage>
                                </h2>
                                <br></br>
                                <ul className="list-unstyled text-white d-flex justify-content-space-between amenities-list" data-aos="fade-up">
                                    <li className="terraza">
                                        <hr></hr>
                                        <p className="text-uppercase mb-0 font-weight-bold">
                                            <a href="#terraza-central">
                                                <FormattedMessage id="app.terraza_central" defaultMessage="Terraza Central"></FormattedMessage>
                                            </a>
                                        </p>
                                        <p className="mb-0"><FormattedMessage id="app.mobiliario_urbano" defaultMessage="Área social al aire libre"></FormattedMessage></p>
                                    </li>
                                    <li className="estancia">
                                        <hr></hr>
                                        <p className="text-uppercase mb-0 font-weight-bold">
                                            <a href="#estancia">
                                                <FormattedMessage id="app.estancia" defaultMessage="Estancia"></FormattedMessage>
                                            </a>
                                        </p>
                                        <p className="mb-0"><FormattedMessage id="app.coworking" defaultMessage="Coworking"></FormattedMessage></p>
                                        <p className="mb-0"><FormattedMessage id="app.salon_privado" defaultMessage="Salón Privado"></FormattedMessage></p>
                                        <p className="mb-0"><FormattedMessage id="app.cava" defaultMessage="Cava"></FormattedMessage></p>
                                        <p className="mb-0"><FormattedMessage id="app.bar" defaultMessage="Bar"></FormattedMessage></p>
                                    </li>
                                    <li className="gimnasio">
                                        <hr></hr>
                                        <p className="text-uppercase mb-0 font-weight-bold">
                                            <a href="#gimnasio">
                                                <FormattedMessage id="app.gimnasio" defaultMessage="Gimnasio"></FormattedMessage>
                                            </a>
                                        </p>
                                        <p className="mb-0"><FormattedMessage id="app.area_de_pesas" defaultMessage="Área de pesas"></FormattedMessage></p>
                                        <p className="mb-0"><FormattedMessage id="app.area_de_cardio" defaultMessage="Área de cardio"></FormattedMessage></p>
                                    </li>
                                    <li className="patios">
                                        <hr></hr>
                                        <p className="text-uppercase font-weight-bold mb-0">
                                            <a href="#patios">
                                                <FormattedMessage id="app.patios" defaultMessage="Patios"></FormattedMessage>
                                            </a>
                                        </p>
                                        <p className="mb-0"><FormattedMessage id="app.areas_de_yoga_calistenia" defaultMessage="Áreas de yoga/calistenia"></FormattedMessage></p>
                                        <p className="mb-0"><FormattedMessage id="app.jardines_contemplativos" defaultMessage="Jardines contemplativos"></FormattedMessage></p>
                                        <p className="mb-0"><FormattedMessage id="app.area_de_juegos_para_niños" defaultMessage="Área de juegos para niños"></FormattedMessage></p>
                                        <p className="mb-0"><FormattedMessage id="app.pet_care" defaultMessage="Pet care"></FormattedMessage></p>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-4 col-lg-3 offset-lg-1">
                                <img src={isotipo} alt="isotipo" className="w-max-100" data-aos="fade-left"></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-2">
                    <div className="container-fluid w14 w-lg-95" id="terraza-central">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 col-lg-6 col-xl-5 m40 mb-md-0" data-aos="fade-right">
                                <h3 className="text-uppercase titulo">
                                    <FormattedMessage
                                        id="app.terraza_central"
                                        defaultMessage="Terraza Central"
                                    ></FormattedMessage>
                                </h3>
                                <hr className="ml-0 m50"></hr>
                                <p className="limit-w">
                                    <FormattedMessage
                                        id="app.gracias_a_sus_amplios_y_comodos_espacios"
                                        defaultMessage='Gracias a sus amplios y cómodos espacios, es el punto de encuentro ideal, sin perder la seguridad de seguir "en casa".'
                                    ></FormattedMessage>
                                </p>
                                <p className="limit-w">
                                    <FormattedMessage
                                        id="app.brindamos_el_balance_perfecto_entre_utilidad"
                                        defaultMessage="Brindamos el balance perfecto entre utilidad y convivencia con la naturaleza. Un lugar diseñado para que puedas hacerlo tuyo."
                                    ></FormattedMessage>
                                </p>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 col-xl-7" data-aos="fade-left">
                                <div dangerouslySetInnerHTML={{ __html: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/542710000?autoplay=1&color=ffffff&title=0&byline=0&portrait=0&loop=10&background=1&muted=1" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>'}} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-2">
                    <div className="container-fluid w14 w-lg-95" id="estancia">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 col-lg-7 order-2 order-md-1 deco" data-aos="fade-right">
                                <div dangerouslySetInnerHTML={{ __html: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/542710742?autoplay=1&color=ffffff&title=0&byline=0&portrait=0&loop=10&background=1&muted=1" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>'}} />
                            </div>
                            <div className="col-12 col-md-6 col-lg-5 pl-lg-5 order-1 order-md-2 m40 mb-md-0" data-aos="fade-left">
                                <h3 className="text-uppercase titulo">
                                    <FormattedMessage id="app.estancia" defaultMessage="Estancia"></FormattedMessage>
                                </h3>
                                <hr className="ml-0 m50"></hr>
                                <p className="limit-w">
                                    <FormattedMessage id="app.una_atmosfera_comoda" defaultMessage="Una atmósfera cómoda, mobiliario urbano, y la capacidad de tener un espacio pensado para tus necesidades. Ya sea reunirse con amigos, familia, o para sesiones de trabajo."></FormattedMessage>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-2">
                    <div className="container-fluid w14 w-lg-95" id="gimnasio">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 col-lg-5 m40a mb-md-0" data-aos="fade-right">
                                <h3 className="text-uppercase titulo">
                                    <FormattedMessage
                                        id="app.gimnasio"
                                        defaultMessage="Gimnasio"
                                    ></FormattedMessage>
                                </h3>
                                <hr className="ml-0 m50"></hr>
                                <p className="limit-w">
                                    <FormattedMessage
                                        id="app.un_espacio_con_equipamiento_moderno"
                                        defaultMessage="Un espacio con equipamiento moderno, zonas especializadas y áreas funcionales, es perfecto para promover un estilo de vida más saludable."
                                    ></FormattedMessage>
                                </p>
                            </div>
                            <div className="col-12 col-md-6 col-lg-7" data-aos="fade-left">
                                <div dangerouslySetInnerHTML={{ __html: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/542710399?autoplay=1&color=ffffff&title=0&byline=0&portrait=0&loop=10&background=1&muted=1" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>'}} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-2">
                    <div className="container-fluid w14 w-lg-95" id="patios">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 col-lg-7 order-2 order-md-1" data-aos="fade-right">
                                <img className="w-max-100" src={img4} alt="patios"></img>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5 pl-lg-5 order-1 order-md-2 m40 mb-md-0" data-aos="fade-left">
                                <h1 className="text-uppercase titulo">
                                    <FormattedMessage id="app.patios" defaultMessage="Patios"></FormattedMessage>
                                </h1>
                                <hr className="ml-0 m50"></hr>
                                <p className="limit-w">
                                    <FormattedMessage id="app.disfruta_de_las_areas_de_contemplacion" defaultMessage="Disfruta las áreas de contemplación, ideales para meditar, descansar y aprovechar al máximo la cal-ma que se vive en Laja. El sitio ideal para pasar tiempo en familia, mientras los pequeños disfrutan de áreas para su desarrollo."></FormattedMessage>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Amenities;