import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import './scss/main.scss';
import NotFound from "./components/errors/404/NotFound";
import Navbar from './components/Navbar';
import Home from './Home';
import Footer from './components/Footer';
import Concept from "./Concept";
import Amenidades from "./Amenities";
import Houses from "./Houses";
import Contact from "./Contact";
import Cotizador from "./Cotizador";
import Gallery from "./Gallery";
import 'aos/dist/aos.css';
import AOS from 'aos';
import ScrollToTop from './components/ScrollToTop';

function App() {
	SwiperCore.use([Autoplay, Navigation]);
	AOS.init({once: true});
	return (
		<Router>
			<div className="App">
				<ScrollToTop>
					<Switch>
						<Route path="/" exact>
							<Navbar color="text-white"></Navbar>
							<Home></Home>
						</Route>
						<Route path="/concept" exact>
							<Navbar color="text-black" bg={true}></Navbar>
							<Concept></Concept>
						</Route>
						<Route path="/amenities" exact>
							<Navbar color="text-white"></Navbar>
							<Amenidades></Amenidades>
						</Route>
						<Route path="/houses">
							<Navbar color="text-black" bg={true}></Navbar>
							<Houses></Houses>
						</Route>
						<Route path="/contact">
							<Navbar color="text-black" bg={true}></Navbar>
							<Contact></Contact>
						</Route>
						<Route path="/quoter">
							<Navbar color="text-black" bg={true}></Navbar>
							<Cotizador></Cotizador>
						</Route>
						<Route path="/quoter#open" exact>
							<Navbar color="text-black" bg={true}></Navbar>
							<Cotizador></Cotizador>
						</Route>
						<Route path="/gallery">
							<Navbar color="text-black" bg={true}></Navbar>
							<Gallery></Gallery>
						</Route>
						<Route path="*">
							<Navbar color="text-black" bg={true}></Navbar>
							<NotFound></NotFound>
						</Route>
					</Switch>
					<Footer></Footer>
				</ScrollToTop>
			</div>
		</Router>
	);
}

export default App;
