import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import './scss/concept.scss';

//Assets
import img1 from './assets/images/cpt1.png';
import img2 from './assets/images/cpt2.jpg';
import img3 from './assets/images/cpt3.jpg';
import javier from './assets/images/javier_munoz.jpg';
import ignacio from './assets/images/ignacio_braga.jpg';
import carlos from './assets/images/carlos_martinez.jpg';

import munoz from './assets/images/munoz.jpg';
import ib from './assets/images/ib.jpg';
import carlos2 from './assets/images/carlos.jpg';

import fig2 from './assets/images/fig_2.svg';

class Concept extends Component {
    render() {
        return (
            <div id="concept">
                <div className="section-2">
                    <div className="container-fluid w14 w-lg-95">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 col-lg-6 col-xl-5 m40 mb-md-0" data-aos="fade-right">
                                <h5 className="text-uppercase subtlo-16 m40">
                                    <FormattedMessage
                                        id="app.about_the_concept"
                                        defaultMessage="Sobre el concepto"
                                    ></FormattedMessage>
                                </h5>
                                <h2 className="text-uppercase titulo-x2 m20">
                                    <FormattedMessage
                                        id="app.balance"
                                        defaultMessage="Balance"
                                    ></FormattedMessage>
                                    <span style={{color: "#7E7D7E"}}> &</span> <br></br>
                                    <FormattedMessage id="app.accuracy" defaultMessage="Exactitud"></FormattedMessage>
                                </h2>
                                <hr className="ml-0 m40"></hr>
                                <p className="limit-w mb-0">
                                    <FormattedMessage
                                        id="app.laja_materializa_una_vision_poetica"
                                        defaultMessage="LAJA® materializa una visión poética originaria de Yucatán y la traduce a un espacio armónico, perfectamente equilibradado desde la forma y el fondo de sus paredes, iluminación y ambiente endémico de la región."
                                    ></FormattedMessage>
                                </p>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 offset-xl-1" data-aos="fade-left">
                                <img className="w-max-100" src={img1} alt="img1"></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-grey-1 section-4 overflow-hidden">
                    <div className="container-fluid w14 w-lg-90">
                        <div className="row align-items-center position-relative justify-content-end">
                            <img src={fig2} alt="figures_section_3" className="deco-2 w-max-100" data-aos="fade-right"></img>
                            <div className="col-12 col-xl-10 m30 mb-sm-0" data-aos="zoom-in">
                                <div dangerouslySetInnerHTML={{ __html: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/533170537?autoplay=1&color=ffffff&title=0&byline=0&portrait=0&loop=10&background=1&muted=1" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>'}} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-2">
                    <div className="container-fluid w14 w-lg-95">
                        <div className="row align-items-center" data-aos="fade-up">
                            <div className="col-12 col-md-6 col-lg-6 order-2 order-md-1">
                                <img className="w-max-100" src={img2} alt="img2"></img>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 col-xl-5 offset-xl-1 order-1 order-md-2 m40 mb-md-0">
                                <p className="text-uppercase subtlo-16 m20">
                                    <FormattedMessage
                                        id="app.about_the_concept"
                                        defaultMessage="Sobre el concepto"
                                    ></FormattedMessage>
                                </p>
                                <h3 className="text-uppercase titulo m10">
                                    <FormattedMessage
                                        id="app.paz_grabada_en_piedra"
                                        defaultMessage="Paz grabada en piedra"
                                    ></FormattedMessage>
                                </h3>
                                <hr className="ml-0 m50"></hr>
                                <p className="mb-0 limit-w">
                                    <FormattedMessage
                                        id="app.los_residentes_disfrutaran_del_lujo_honesto"
                                        defaultMessage="Los residentes disfrutarán del lujo honesto y la atención al detalle, un contexto idóneo de paz en una comunidad cuidada, libre y bien diseñada. Brindamos a todos nuestros residentes un estilo de vida seguro y cómodo y al alcance de todos los mejores servicios y amenidades de la zona."
                                    ></FormattedMessage>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-2">
                    <div className="container-fluid w14 w-lg-90">
                        <div className="row align-items-center" data-aos="fade-up">
                            <div className="col-12 col-md-6 col-lg-5">
                                <p className="text-uppercase subtlo-16">
                                    <FormattedMessage
                                        id="app.about_the_concept"
                                        defaultMessage="Sobre el concepto"
                                    ></FormattedMessage>
                                </p>
                                <h3 className="text-uppercase titulo m10">
                                    <FormattedMessage
                                        id="app.creadores"
                                        defaultMessage="CREADORES"
                                    ></FormattedMessage>
                                </h3>
                                <hr className="ml-0 m50"></hr>
                                <p className="mb-0 limit-w">
                                    <FormattedMessage
                                        id="app.la_esencia_de_laja_se_traduce_a_un_espacio_armonico"
                                        defaultMessage="La esencia de LAJA se traduce a un espacio armónico, perfectamente equilibrado. Desde la forma y el fondo de sus conceptos, hasta la iluminación y el uso de materiales endémicos de la región"
                                    ></FormattedMessage>
                                </p>
                            </div>
                            <div className="col-12 col-md-6 col-lg-7">
                                <img className="w-max-100" src={img3} alt="img3"></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-3">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-sm-6 col-lg-4" data-aos="zoom-in">
                                <img src={munoz} alt="ej"></img>
                                <div className="card w-100" style={{border: "0px"}}>
                                    <div className="bgg" style={{backgroundImage: `url(${javier})`}}>
                                        <img src={javier} alt="card1"/>
                                    </div>
                                    <div className="card-body pl-0">
                                        <p className="tlo black mb-0 text-uppercase">Javier Muñoz</p>
                                        <p className="tlo mb-0 text-uppercase">MUÑOZ ARQUITECTOS</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4" data-aos="zoom-in">
                                <img src={ib} alt="ej" style={{marginTop:"12.5px",marginBottom:"12.5px"}}></img>
                                <div className="card w-100" style={{border: "0px"}}>
                                    <div className="bgg" style={{backgroundImage: `url(${ignacio})`}}>
                                        <img src={javier} alt="card1"/>
                                    </div>
                                    <div className="card-body pl-0">
                                        <p className="tlo black mb-0 text-uppercase">Ignacio Braga</p>
                                        <p className="tlo mb-0 text-uppercase">IB Arquitectura</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4" data-aos="zoom-in">
                                <img src={carlos2} alt="ej" style={{marginTop:"17.5px", marginBottom:"17.5px"}}></img>
                                <div className="card w-100" style={{border: "0px"}}>
                                    <div className="bgg" style={{backgroundImage: `url(${carlos})`}}>
                                        <img src={javier} alt="card1"/>
                                    </div>
                                    <div className="card-body pl-0">
                                        <p className="tlo black mb-0 text-uppercase">Carlos Martínez</p>
                                        <p className="tlo mb-0 text-uppercase">Bienal</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Concept;