import React, { Component } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import logoWhite from '../assets/images/logo_white.svg';
import logoBlack from '../assets/images/logo_black.svg';
import {FormattedMessage} from 'react-intl';
import { NavLink } from 'react-router-dom';
import Burguer from './Burguer';

class Navbar extends Component {
	setLanguage(lan){
		localStorage.setItem('language', lan);
		window.location.reload(false);
	}
	updateBody(color = ''){
		if(document.querySelector('.App')){
			if(color === ''){
				document.querySelector('.App').classList.remove('bg-grey-3');
			}else{
				document.querySelector('.App').classList.add(color);
			}
		}
	}
    render() {
        return (
            <div className={`main-nav ${this.props.bg ? 'bg-2' : ''}`}>
				<div className="d-none d-md-block">
					<Container fluid className="languages w15 w-lg-90">
						<Row className="pb-3 pt-3 align-items-center">
							<Col className="col-8">
								<ul className={`list-unstyled d-flex list-nav mb-0 ${this.props.color}`}>
									<li>
										<a href="/contact#location">
											<FormattedMessage id="app.location" defaultMessage="Ubicación"></FormattedMessage>
										</a>
									</li>
									<li><a href="tel:+529999552022">T. (999) 955 2022</a></li>
									<li>YUCATÁN, MX</li>
								</ul>
							</Col>
							<Col className="col-4 text-right">
								<span className={this.props.color}>
									<a className={`${this.props.color} ${(localStorage.getItem('language') === 'es') ? 'active' : ''}`} href="#!" onClick={() => {this.setLanguage('es')}}>ESP</a> <a className={`${this.props.color} ${(localStorage.getItem('language') === 'en') ? 'active' : ''}`} href="#!" onClick={() => {this.setLanguage('en')}}>ENG</a>
								</span>
							</Col>
						</Row>
					</Container>
					<Container className="container-fluid w15 w-lg-90 pt-3 pb-3">
						<ul className={`list-unstyled list-menu d-flex align-items-center justify-content-between ${this.props.color}`}>
							<li className="text-uppercase">
								<NavLink to="/concept" activeClassName="active" onClick={() => {this.updateBody()}}>
									<FormattedMessage id="app.concept" defaultMessage="Concepto" ></FormattedMessage>
								</NavLink>
							</li>
							<li className="text-uppercase">
								<NavLink to="/amenities" activeClassName="active" onClick={() => {this.updateBody()}}>
									<FormattedMessage id="app.amenities" defaultMessage="Amenidades" ></FormattedMessage>
								</NavLink>
							</li>
							<li className="text-uppercase" onClick={() => {this.updateBody()}}>
								<NavLink to="/houses" activeClassName="active" onClick={() => {this.updateBody()}}>
									<FormattedMessage id="app.houses" defaultMessage="Casas Ensamble" ></FormattedMessage>
								</NavLink>
							</li>
							<li className="text-uppercase logo-img">
								<NavLink to="/" activeClassName="active" onClick={() => {this.updateBody()}}>
									<img src={(this.props.bg) ? logoBlack : logoWhite} alt="Logo" style={{width: "100%"}}></img>
								</NavLink>
							</li>
							<li className="text-uppercase">
								<NavLink to="/quoter" activeClassName="active" onClick={() => {this.updateBody('bg-grey-3')}}>
									<FormattedMessage id="app.quoter" defaultMessage="Cotizador" ></FormattedMessage>
								</NavLink>
							</li>
							<li className="text-uppercase">
								<NavLink to="/gallery" activeClassName="active" onClick={() => {this.updateBody()}}>
									<FormattedMessage id="app.gallery" defaultMessage="Galería" ></FormattedMessage>
								</NavLink>
							</li>
							<li className="text-uppercase">
								<NavLink to="/contact" activeClassName="active" onClick={() => {this.updateBody()}}>
									<FormattedMessage id="app.contact" defaultMessage="Contacto" ></FormattedMessage>
								</NavLink>
							</li>
						</ul>
					</Container>
				</div>
				<Burguer bg={this.props.bg} updateBody={this.updateBody}></Burguer>
            </div>
        );
    }
}

export default Navbar;