import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FormattedMessage } from 'react-intl';
import Brochure from '../components/modals/Brochure';

import sello from '../assets/images/casa_kab/02_sello.svg';

import sello_kab from '../assets/images/casa_kab/03_kab.svg';
import img_kab from '../assets/images/casa_kab/01_imagen.jpg';
import plano_kab from '../assets/images/casa_kab/04_plano.jpg';
import patron from '../assets/images/casa_kab/05_patron.svg';
import cocina from '../assets/images/casa_kab/cocina.jpg';
import recamara from '../assets/images/casa_kab/recamara.jpg';

require('@fancyapps/fancybox');
require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');

class CasaKab extends Component {
    constructor(props){
        super(props);
        this.state = {
            show: false
        }
    }
    setShow = (bool) => {
        this.setState({...this.state, show: bool});
    }
    showSwiper(){
        if(this.props.active){
            return <Swiper 
                slidePerView={1}
                navigation
                autoplay={{delay: 3000}}
                autoHeight
            >
                <SwiperSlide>
                    <div className="bgg" style={{backgroundImage: `url(${cocina})`}}>
                        <img src={cocina} alt="galeria1"></img>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="bgg" style={{backgroundImage: `url(${recamara})`}}>
                        <img src={recamara} alt="galeria2"></img>
                    </div>
                </SwiperSlide>
            </Swiper>
        }else{
            return <></>;
        }
    }
    render() {
        return (
            <div id="casaKab" className={(this.props.active) ? '' : 'd-none'}>
                <Brochure show={this.state.show} setShow={this.setShow}></Brochure>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-12 p-5">
                            <div className="mw-75 m-auto title-sect">
                                <h1 className="text-uppercase"><FormattedMessage id="app.casa_kab" defaultMessage="Casa Kab"></FormattedMessage></h1>
                                <div className="w-100" style={{borderBottom: "1px solid #000"}}>
                                    <img src={sello} className="mb-3" alt="sello"></img>
                                    <img src={sello_kab} className="ml-3" alt="sello_kab"></img>
                                </div>
                                <p className="mt-4 text-1 text-grey-2">
                                    <FormattedMessage id="app.casa_kab_destaca_por_su_amplitud" defaultMessage="Casa Kab destaca por su amplitud de espacios en perfecta sintonía con la iluminación y acabados naturales, cuyo objetivo es crear el espacio ideal para encontrar tu paz interior."></FormattedMessage>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 bg-grey-1 p-0">
                            <img className="w-100" src={img_kab} alt="g"></img>
                        </div>
                    </div>
                    <div className="row align-items-stretch bg-grey-3">
                        <div className="col-lg-6 col-sm-12 pt-5 pb-5">
                            <div className="mw-75 m-auto">
                                <p className="text-grey-2 text-uppercase">
                                    <FormattedMessage id="app.amenidades_de_la_casa_kab" defaultMessage="Amenidades de la casa Kab"></FormattedMessage>
                                </p>
                                <p className="mb-1 text-uppercase col-12 pt-4" style={{borderTop:"2px solid #ccc",color:"#000"}}><b><FormattedMessage id="app.planta_baja" defaultMessage="Planta baja"></FormattedMessage></b></p>
                                <ul className="text-grey-2" style={{WebkitColumnCount: 2,MozColumnCount: 2, columnCount: 2}}>
                                    <li><FormattedMessage id="app.cuarto_de_lavado" defaultMessage="Cuarto de lavado"></FormattedMessage></li>
                                    <li><FormattedMessage id="app.alacena" defaultMessage="Alacena"></FormattedMessage></li>
                                    <li><FormattedMessage id="app.terraza" defaultMessage="Terraza"></FormattedMessage></li>
                                    <li><FormattedMessage id="app.cocina" defaultMessage="Cocina"></FormattedMessage></li>
                                    <li><FormattedMessage id="app.sala_comedor" defaultMessage="Sala / Comedor"></FormattedMessage></li>
                                    <li><FormattedMessage id="app.cuarto_de_servicio_con_baño_completo" defaultMessage="Cuarto de servicio con baño completo"></FormattedMessage></li>
                                    <li><FormattedMessage id="app.recamara_con_baño_completo" defaultMessage="Recámara con baño completo"></FormattedMessage></li>
                                    <li><FormattedMessage id="app.jardin_privado" defaultMessage="Jardín Privado"></FormattedMessage></li>
                                </ul>
                                <p className="mb-1 text-uppercase col-12 pt-4" style={{borderTop:"2px solid #ccc",color:"#000"}}><b><FormattedMessage id="app.planta_alta" defaultMessage="PLanta Alta"></FormattedMessage></b></p>
                                <ul className="text-grey-2">
                                    <li><FormattedMessage id="app.recamara_principal_con_closet_vestidor_y_baño_completo" defaultMessage="Recámara principal con clóset vestidor y baño completo"></FormattedMessage></li>
                                    <li><FormattedMessage id="app.segunda_recamara_con_closet_vestidor_y_baño_completo" defaultMessage="Segunda recámara con clóset vestidor y baño completo"></FormattedMessage></li>
                                    <li><FormattedMessage id="app.closet_de_blancos" defaultMessage="Clóset de blancos"></FormattedMessage></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12" style={{backgroundColor: "#D1CDC9"}}>
                            <a data-fancybox="plano_kab" href={plano_kab}>
                                <img src={plano_kab} alt="plano_kab" className="w-100"></img>
                            </a>
                        </div>
                    </div>
                    <br></br>
                    <div className="mw-75 cp-3 text-center mr-auto ml-auto mt-5 mb-5" style={{background: "url("+patron+")"}}>
                        <a href="#!" className="btn-style-1 bg-grey-1 text-white text-decoration-none" onClick={() => {this.setShow(true)}}><FormattedMessage id="app.descargar_brochure" defaultMessage="Descargar Brochure"></FormattedMessage></a>
                    </div>
                </div>
                <div className="container mt-5 mb-5 pb-5 pt-5">
                    {this.showSwiper()}
                </div>
            </div>
        );
    }
}

export default CasaKab;