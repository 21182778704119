import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
//Styles
import './scss/home.scss';
import "swiper/swiper.scss";
import 'swiper/components/navigation/navigation.scss';
//Assets
// import img1 from './assets/images/img1.png';
import logo_organico from './assets/images/logo_organico.svg';
import img2 from './assets/images/img2.png';
import figures_3 from './assets/images/figures_section_3.svg';
import img3 from './assets/images/img3.jpg';
import img4 from './assets/images/img4.jpg';
import figures_4 from './assets/images/figuras_section_4.svg';
import home_gal1 from './assets/images/home_gal1.jpg';
import home_gal2 from './assets/images/home_gal2.jpg';
import home_gal3 from './assets/images/home_gal3.jpg';
import home_gal4 from './assets/images/home_gal4.jpg';
import home_gal5 from './assets/images/home_gal5.jpg';
import kab_blanco from './assets/images/kab_blanco.svg';
import hun_blanco from './assets/images/hun_blanco.svg';
import arrow_down from './assets/images/arrow_down.svg';

class Home extends Component {
    render() {
        return (
            <div id="main-home">
                <div className="section-1" id="home">
                    <div className="container">
                        <div className="row align-items-end justify-content-end">
                            <div className="col-12 col-sm-9 offset-sm-1 deco position-relative">
                                <div dangerouslySetInnerHTML={{ __html: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/542709410?autoplay=1&color=ffffff&title=0&byline=0&portrait=0&loop=10&background=1&muted=1" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>'}} />
                            </div>
                            <div className="col-12 col-sm-2 d-none d-sm-inline">
                                <img className="m30 w-max-100" src={logo_organico} alt="logo_organic"></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-2">
                    <div className="container-fluid w14 w-lg-95">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 m40 mb-lg-0" data-aos="fade-up">
                                <h5 className="m40 m-lg-70 text-uppercase subtlo-16 animate__animated animate__fadeInUp">
                                    <FormattedMessage id="app.about_the_concept" defaultMessage="Sobre el concepto" ></FormattedMessage>
                                </h5>
                                <h2 className="text-uppercase titulo-x2 mb-0">
                                    <FormattedMessage
                                        id="app.equilibrio_poetico"
                                        defaultMessage="Equilbrio Poético"
                                    ></FormattedMessage>
                                </h2>
                                <hr className="ml-0"></hr>
                                <p className="limit-w m40">
                                    <FormattedMessage
                                        id="app.laja_propone_una_comunidad_equilibrada"
                                        defaultMessage="LAJA® propone una comunidad equilibrada, donde la naturaleza y el arte conviven en armonía. Una vida calmada y emocionante, atenta y cuidada. Plena de lo necesario, lo justo y lo importante."
                                    ></FormattedMessage>
                                </p>
                                <Link to="/concept">
                                    <button className="btn btn-black text-uppercase mr-4">
                                        <FormattedMessage
                                            id="app.know_more"
                                            defaultMessage="Conoce más"
                                        ></FormattedMessage>
                                    </button>
                                </Link>
                                <Link to="/contact">
                                    <button className="btn btn-blanco-underline-arrow text-uppercase">
                                        <FormattedMessage
                                            id="app.contact_us"
                                            defaultMessage="Contáctanos"
                                        ></FormattedMessage>
                                    </button>
                                </Link>
                            </div>
                            <div className="col-12 col-md-6">
                                <img src={img2} alt="img2" className="w-max-100" data-aos="fade-up"></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-3 overflow-hidden">
                    <div className="container-fluid w15 w-lg-90">
                        <div className="row align-items-center mb-5">
                            <div className="col-12 col-lg-6" data-aos="fade-up">
                                <h2 className="titulo-x2 text-uppercase m40">
                                    <FormattedMessage
                                        id="app.houses_registered"
                                        defaultMessage="Casas ensamble"
                                    ></FormattedMessage>
                                    <small>®</small>
                                </h2>
                            </div>
                            <div className="col-12 col-lg-6 text-center text-lg-right">
                                <img data-aos="fade-up" src={figures_4} alt="figures_section_4"></img>
                            </div>
                        </div>
                        <div className="row align-items-center position-relative justify-content-end">
                            <img src={figures_3} alt="figures_section_3" className="deco-2 w-max-100"  data-aos="fade-right"></img>
                            <div className="col-12 col-sm-6 col-xl-5 m30 mb-sm-0" data-aos="zoom-in">
                                <a href="/houses#casaKab" className="blur-hover position-relative">
                                    <div className="bgg" style={{backgroundImage: `url(${img3})`}}>
                                        <img src={img3} alt="img3" style={{width:"100%"}}></img>
                                    </div>
                                    <div className="s-hover position-absolute">
                                        <img src={kab_blanco} alt="kab_white" className="logo m50"></img>
                                        <p className="text-white mt-2"><FormattedMessage id="app.ver_mas" defaultMessage="Ver más"></FormattedMessage></p>
                                        <img src={arrow_down} alt="arrow_down"></img>
                                    </div>
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-xl-5"  data-aos="zoom-in">
                                <a href="/houses#casaHun" className="blur-hover position-relative">
                                    <div className="bgg" style={{backgroundImage: `url(${img4})`}}>
                                        <img src={img4} alt="img3" style={{width:"100%"}}></img>
                                    </div>
                                    <div className="s-hover position-absolute">
                                        <img src={hun_blanco} alt="hun_white" className="logo m50"></img>
                                        <p className="text-white mt-2"><FormattedMessage id="app.ver_mas" defaultMessage="Ver más"></FormattedMessage></p>
                                        <img src={arrow_down} alt="arrow_down"></img>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-4">
                    <div className="container-fluid w15 w-lg-90">
                        <Swiper
                            slidesPerView={1}
                            navigation
                            autoplay={{delay: 3000}}
                        >
                            <SwiperSlide>
                                <div className="bgg" style={{backgroundImage: `url(${home_gal1})`}}>
                                    <img src={home_gal1} alt="home_gal1"></img>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="bgg" style={{backgroundImage: `url(${home_gal2})`}}>
                                    <img src={home_gal1} alt="home_gal1"></img>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="bgg" style={{backgroundImage: `url(${home_gal3})`}}>
                                    <img src={home_gal1} alt="home_gal1"></img>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="bgg" style={{backgroundImage: `url(${home_gal4})`}}>
                                    <img src={home_gal1} alt="home_gal1"></img>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="bgg" style={{backgroundImage: `url(${home_gal5})`}}>
                                    <img src={home_gal1} alt="home_gal1"></img>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;